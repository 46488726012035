import React from 'react';
const AVAILABLE_SORT_FIELDS = {
    'call.direction': 'call.direction',
    'call.started_at': 'call.started_at',
    'call.inbound_number': 'call.inbound_number',
    'call.source_system': 'call.source_system',
    'call.duration_millis': 'call.duration_millis',
    'call.outbound_number': 'call.outbound_number',
    'call.participants': 'call.participants.name',
};
export const useSort = () => {
    const [sortModel, setSortModel] = React.useState([]);
    const getServerSortParams = React.useCallback(function getFilterUrlParams() {
        const [firstSort] = sortModel;
        if (!firstSort)
            return {};
        const { field, sort } = firstSort;
        return {
            sort_by: AVAILABLE_SORT_FIELDS[field],
            sort_direction: sort !== null && sort !== void 0 ? sort : undefined,
        };
    }, [sortModel]);
    return {
        serverSortParams: getServerSortParams(),
        sortingMode: 'server',
        sortModel,
        onSortModelChange: setSortModel,
    };
};
