import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useDataGridColumns } from '../utils';
import { getOperator, modifyFilterValue } from './utils';
//TODO: Change "string" to server type structure "SearchParams" from mission-control-api
const AVAILABLE_FILTERS_FIELDS = {
    'call.direction': 'call.direction',
    'call.started_at': 'call.started_at',
    'call.inbound_number': 'call.inbound_number',
    'call.source_system': 'call.source_system',
    'call.duration_millis': 'call.duration_millis',
    'call.outbound_number': 'call.outbound_number',
    'call.participants': 'call.participants.user_id',
};
export const useFilters = () => {
    const columnsToTypes = useDataGridColumns().reduce((acc, c) => (Object.assign(Object.assign({}, acc), { [c.field]: c.type })), {});
    const [filterModel, setFilterModel] = useState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce((newFilterModel) => {
        setFilterModel(newFilterModel);
    }, 300), []);
    const getServerFilterParams = useCallback(function getFilterUrlParams() {
        if (!filterModel)
            return {};
        return filterModel.items.reduce((acc, item) => {
            const field = AVAILABLE_FILTERS_FIELDS[item.field];
            const operator = getOperator(item.operator, columnsToTypes[item.field]);
            const value = modifyFilterValue(item.field, item.value, item.operator);
            return Object.assign(Object.assign({}, acc), { [`${field}.${operator}`]: value });
        }, {});
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterModel, columnsToTypes]);
    return {
        serverFilterParams: getServerFilterParams(),
        filterModel,
        onFilterChange: onFilterChange,
    };
};
