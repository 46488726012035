import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { repositories } from 'core/repositories';
import { getGridDateOperators, getGridNumericOperators, } from 'lib/cortiUI';
import { DurationFilterInput } from '../Grid/GridFilterTimeInput';
export function useDataGridColumns() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const AVAILABLE_DURATION_OPERATORS = [
        { value: '=', label: t('equal', 'is equal to') },
        { value: '!=', label: t('notEqual', 'is not equal to') },
        { value: '>', label: t('gt', 'is greater than') },
        { value: '>=', label: t('gte', 'is greater than or equal to') },
        { value: '<', label: t('lt', 'less than') },
        { value: '<=', label: t('lte', 'is less than or equal to') },
        { value: 'isEmpty', label: t('empty', 'is empty') },
        { value: 'isNotEmpty', label: t('notEmpty', 'is not empty') },
    ];
    const durationFilterRelatedProperties = {
        type: 'number',
        filterOperators: getGridNumericOperators()
            .filter((item) => AVAILABLE_DURATION_OPERATORS.some((operator) => operator.value === item.value))
            .map((item) => {
            const operator = AVAILABLE_DURATION_OPERATORS.find((op) => op.value === item.value);
            return Object.assign(Object.assign({}, item), { label: operator ? operator.label : item.label, InputComponent: item.requiresFilterValue !== false ? DurationFilterInput : undefined });
        }),
    };
    return [
        {
            field: 'call.started_at',
            headerName: t('startTime', 'Started at'),
            type: 'dateTime',
            valueFormatter: (value) => formatDateTime(value),
            flex: 2,
            filterOperators: getGridDateOperators(true).filter((i) => AVAILABLE_DATE_OPERATORS.includes(i.value)),
        },
        {
            field: 'call.inbound_number',
            headerName: t('inboundPhone', 'Receiver Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.direction',
            headerName: t('direction', 'Direction'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueOptions: ['INBOUND', 'OUTBOUND', 'INTERNAL'],
        },
        {
            field: 'call.source_system',
            headerName: t('sourceSystem', 'Source System'),
            flex: 2,
            type: 'string',
            filterable: true,
        },
        Object.assign({ field: 'call.duration_millis', headerName: t('duration', 'Duration'), flex: 2, align: 'left', headerAlign: 'left', filterable: true, valueFormatter: (value) => Formatters.msToDuration(value) }, durationFilterRelatedProperties),
        {
            field: 'call.outbound_number',
            headerName: t('outboundPhone', 'Caller Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.participants',
            headerName: t('participants', 'Participants'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueFormatter: (value) => value.join(','),
            valueOptions: repositories.users.users.map((u) => ({ label: u.name, value: u.id })),
            sortable: true,
        },
    ];
}
const AVAILABLE_DATE_OPERATORS = [
    'after',
    'onOrAfter',
    'before',
    'onOrBefore',
    'isEmpty',
    'isNotEmpty',
];
